import axios from "axios";
import React, { useEffect, useState } from "react";
import { Row } from 'antd';

export default function ViewSalary({ empId, handleClose, empName }) {
    const [employee, setEmployee] = useState({
        id: "",
        empNo: "",
        amount: "",
        incentive: "",
        effectiveDate: ""
    });

    const [emp, setEmp] = useState({
        empNo: empId,
        amount: "",
        incentive: "",
        effectiveDate: ""
    });

    const [viewSalary, setViewSalary] = useState(false);

    const { amount, incentive, effectiveDate } = emp;

    useEffect(() => {
        loadSalary(empId);
    }, [empId]);

    const onInputChange = (e) => {
        setEmp({ ...emp, [e.target.name]: e.target.value });
    };

    const loadSalary = async (id) => {
        const result = await axios.get(`https://hr-demo-app.mainservice.demo.3iii.ai/api/salaries/${id}`);
        console.log('result-data', result)
        if (result.data == "") {
            setViewSalary(false);
        } else {
            setViewSalary(true);
            setEmployee(result.data);
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        await axios.post("https://hr-demo-app.mainservice.demo.3iii.ai/api/salaries", emp);
        handleClose(false);
    };

    return (
        <>
            {viewSalary ? (

                <Row justify='center'>
                    <div style={{ position: 'absolute', top: '10%', left: '5%', width: '70%' }}>
                        <div>
                            <div className="col-md-6 offset-md-3 border rounded p-4 mt-2 shadow" style={{ background: 'white' }}>
                                <h2 className="text-center m-4">Employee Details</h2>

                                <div className="card">
                                    <div className="card-header">
                                        <b>Salary Details of</b> {employee.name}
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <b>ID:</b> {employee.id}
                                            </li>
                                            <li className="list-group-item">
                                                <b>Employee No:</b> {employee.empNo}
                                            </li>
                                            <li className="list-group-item">
                                                <b>Amount:</b> {employee.amount}
                                            </li>
                                            <li className="list-group-item">
                                                <b>Incentive:</b> {employee.incentive}
                                            </li>
                                            <li className="list-group-item">
                                                <b>Effective Date:</b> {employee.effectiveDate}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <button className="btn btn-outline-primary" onClick={() => { handleClose(false) }}>
                                    Back to UI
                                </button>
                            </div>
                        </div>
                    </div>
                </Row>
            ) : (
                <Row justify='center'>
                    <div style={{ position: 'absolute', top: '10%', left: '5%', width: '70%' }}>
                        <div className="col-md-6 offset-md-3 border rounded p-4 mt-2 shadow" style={{ background: 'white' }}>
                            <h2 className="text-center m-4">Add Salary for {empName}</h2>

                            <form onSubmit={onSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Enter amount</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Salary"
                                        name="amount"
                                        value={amount}
                                        onChange={onInputChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Enter incentive</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter incentive"
                                        name="incentive"
                                        value={incentive}
                                        onChange={onInputChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Effective Date From</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        placeholder="Effective Date From"
                                        name="effectiveDate"
                                        value={effectiveDate}
                                        onChange={onInputChange}
                                        required
                                    />
                                </div>
                                <button type="submit" className="btn btn-outline-primary">Submit</button>
                                <button type="button" className="btn btn-outline-danger mx-2" onClick={() => handleClose(false)}>Cancel</button>
                            </form>
                        </div>
                    </div >
                </Row>
            )
            }
        </>
    );
}


