import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './home.css';
import Check from './demo';
import { Row, Col } from 'antd';
import Navbar from '../layout/Navbar';
import Editemp from '../emp/Editemp';
import Viewemp from '../emp/Viewemp';
import ViewSalary from '../emp/ViewSalary';

export default function Home() {
  const [emp, setEmp] = useState([]);
  const [editemp, setEditEmp] = useState(false);
  const [viewEmp, setViewEmp] = useState(false);
  const [viewSalary, setViewSalary] = useState(false);
  // const [test, setTest] = useState(false);
  const [selectedEmpID, setSelectedEmpID] = useState(null);
  const [selectedEmpName, setSelectedEmpName] = useState("");

  useEffect(() => {
    loadEmp();
  }, []);

  const loadEmp = async () => {
    const result = await axios.get('https://hr-demo-app.mainservice.demo.3iii.ai/api/v1/get-employees');
    setEmp(result.data);
  };

  const deleteEmp = async (id) => {
    const shouldDelete = window.confirm('Are you sure you want to delete this employee?');
    if (shouldDelete) {
      await axios.delete(`https://hr-demo-app.mainservice.demo.3iii.ai/api/v1/${id}`);
      loadEmp();
    }
  };

  const handleEditClick = (id) => {
    setEditEmp(true);
    setSelectedEmpID(id);
  };

  const handleViewClick = (id) => {
    setViewEmp(true);
    setSelectedEmpID(id);
    console.log(id);
  };

  const handleViewSalary = (id, name) => {
    // setTest(true);
    setViewSalary(true);
    setSelectedEmpID(id);
    setSelectedEmpName(name);
    console.log(id);
  };

  const handleCloseEdit = (updated) => {
    setEditEmp(false);
    if (updated) {
      loadEmp(); // Refresh the employee list after editing
    }
  };

  const handleAddUserClose = (updated) => {
    // console.log('homeupdated', updated);
    if (updated) {
      loadEmp(); // Refresh the employee list after adding a new employee
    }
  };

  return (
    <>
      <center>
        <Row style={{ width: '100%' }} justify='center'>
          <div style={{ width: '70%' }}>
            <Navbar refreshEmpList={handleAddUserClose} />
            <div className="table-container">
              <div className="py-4">
                <table className="table table-bordered table-hover shadow">
                  <thead className="table-primary">
                    <tr>
                      <th scope="col">S No</th>
                      <th scope="col">Employee_NO</th>
                      <th scope="col">Name</th>
                      <th scope="col">Dept</th>
                      <th scope="col">Role</th>
                      <th scope="col" style={{ textAlign: 'center' }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {emp.map((empl, index) => (
                      <tr
                        key={index}
                        className={index % 2 === 0 ? 'table-success' : 'table-info'}
                      >
                        <td>{index + 1}</td>
                        <td>{empl.empNo}</td>
                        <td>{empl.name}</td>
                        <td>{empl.department}</td>
                        <td>{empl.role}</td>
                        <td style={{ display: 'flex', justifyContent: 'center' }}>
                          <button
                            className="btn btn-outline-primary small-btn"
                            onClick={() => handleViewClick(empl.empNo)}
                          >
                            View
                          </button>

                          <button
                            className="btn btn-outline-primary small-btn"
                            onClick={() => handleEditClick(empl.empNo)}
                          >
                            Edit
                          </button>

                          <button
                            className="btn btn-outline-primary small-btn"
                            onClick={() => handleViewSalary(empl.empNo, empl.name)}
                          >
                            View Salary
                          </button>

                          <button
                            className="btn btn-danger small-btn"
                            onClick={() => deleteEmp(empl.empNo)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {editemp && (
            <Editemp empId={selectedEmpID} closeEdit={handleCloseEdit} />
          )}
          {viewEmp && (
            <Viewemp empId={selectedEmpID} closeView={setViewEmp} />
          )}
          {viewSalary && (
            <ViewSalary empId={selectedEmpID} empName={selectedEmpName} handleClose={setViewSalary} />
          )}
        </Row>
      </center>
    </>
  );
}
